import api from "~/utils/api/api";

export const startAuction = async (appointment_id: string) => {
  try {
    const res = await api.post(`backoffice/auctions/begin/`, {
      appointment_id: appointment_id,
    });
    return res.data;
  } catch (error) {
    console.log('Error details:', error?.response?.data);
    throw new Error(error?.response?.data?.err || error?.toString() || 'Failed to start auction');
  }
};
