import React, { useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import { navigate } from "gatsby-link";

import UploadOperativeExtraFiles from "~/components/Appointments/UploadOperativeExtraFiles";
import appointmentService from "~/utils/api/v1/appointmentService";
import { OperativeAppointmentData } from "~/utils/interfaces/Appointment";

function OperativeData({
  id,
  reload,
  setReload,
}: {
  id: string;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [operativeData, setOperativeData] = useState<OperativeAppointmentData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (reload) {
      appointmentService.getOperativeData(id).then((res) => {
        setOperativeData(res.data.data);
      });
      setLoading(false);
      setReload(false);
    }
  }, [reload]);

  const formatAmountWithDots = (amount: number) => {
    return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  return (
    <div className="bg-gray-200 rounded-lg p-4 inline-block md:flex-basis-45 flex-grow">
      <div className="flex justify-between">
        <h3 className="mb-2">Operative Data</h3>
        {operativeData && operativeData.appointment && (
          <Button
            color="primary"
            variant="text"
            size="small"
            onClick={() => {
              navigate(`/appointment/${operativeData.appointment}/operative-edit?return_url=${location.pathname}`, {
                state: operativeData,
              });
            }}
          >
            &nbsp;Corregir datos&nbsp;
          </Button>
        )}
      </div>
      {!loading && operativeData && (
        <div className="flex flex-col">
          <div className="flex flex-col">
            <p className="my-2">Nombre: {operativeData.name}</p>
            <p className="my-2">Lead: {operativeData.lead}</p>
            <p className="my-2">Seller: {operativeData.seller.full_name}</p>
            <p className="my-2">Precio base: {operativeData.base_price}</p>
            <p className="my-2">Precio por persona: {operativeData.person_price}</p>
          </div>
          <div className="flex flex-col">
            <h4 className="mb-1 mt-2">Company</h4>
            <p className="my-2">Name: {operativeData.company.name}</p>
            {operativeData.company.document_id && <p className="my-2">RUT: {operativeData.company.document_id}</p>}
            <p className="my-2">Contacto: {operativeData.company.contact_name}</p>
            <p className="my-2">Email: {operativeData.company.contact_email}</p>
            <p className="my-2">Phone: {operativeData.company.contact_phone}</p>
          </div>
          <div className="flex flex-col">
            <h4 className="mb-1 mt-2">Pago</h4>
            <p className="my-2">Monto: {formatAmountWithDots(operativeData.payment.amount)}</p>
            <p className="my-2">Status: {operativeData.payment.status}</p>
            <p className="my-2">N° de Documento: {operativeData.payment.gateway_identifier}</p>
          </div>
          <div className="flex flex-col">
            <h4 className="mb-1 mt-2">HT's</h4>
            <div className="flex flex-col gap-y-2 w-1/2 my-2">
            {operativeData.appointment_nurses.map((appointment_nurse) => (
              <div className="bg-white flex flex-col gap-y-2 rounded-lg p-2" key={appointment_nurse.id}>
                <p className="my-2">Nombre: {appointment_nurse.nurse.user.full_name}</p>
                <p className="my-2">Pago bruto: {appointment_nurse.gross_ht_payment}</p>
              </div>
            ))}
            </div>
          </div>
          {operativeData.company.name === "cchc" && (
            <UploadOperativeExtraFiles
              appointmentId={id}
              source={operativeData.company.name}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default OperativeData;
