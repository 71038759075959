import React, { useState } from "react";

import { Button, Popconfirm, message, notification } from "antd";
import clsx from "clsx";

import appointmentService from "~/utils/api/v1/appointmentService";

type AppointmentManualConfirmProps = {
  appointmentId?: string;
  className?: string;
};

function AppointmentManualConfirm({ appointmentId, className }: AppointmentManualConfirmProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleConfirmChange() {
    setIsLoading(true);
    try {
      await appointmentService.manualConfirm(appointmentId!, {
        force_confirmation: true,
      });
      message.success("Cambio realizado");
      window.location.reload();
    } catch (error) {
      notification.error({
        message: "No se pudo realizar el cambio",
        description: <div className="flex text-g1">{(error as Error).toString()}</div>,
        duration: 10,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={clsx("w-full", className)}>
      <label className="text-g3 font-medium text-sm">Confirmación manual</label>
      <Popconfirm
        title="Confirmar acción"
        placement="top"
        okText="Sí, confirmar"
        cancelText="Cancelar"
        onConfirm={handleConfirmChange}
        cancelButtonProps={{ disabled: isLoading }}
      >
        <Button
          type="primary"
          className="bg-examedi-blue-strong"
        >
          Confirmar
        </Button>
      </Popconfirm>
    </div>
  );
}

export default AppointmentManualConfirm;
