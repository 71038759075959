import { AxiosResponse } from "axios";

import client from "~/utils/api/api";
import { parseError } from "~/utils/api/parseError";
import { SupportedCountryCode } from "~/utils/constants/places";
import {
  MonthsWithPayments,
  NurseMonthSummary,
  NursePayment,
  NursePaymentDaySummary,
  NursePaymentResponse,
} from "~/utils/interfaces/nursePayments";

export async function getMonthsWithPayments(
  country: SupportedCountryCode,
): Promise<MonthsWithPayments[] | { error: string }> {
  try {
    const { data } = await client.get("/v2/nurse-payments/months-with-payments/", {
      params: {
        country,
      },
    });
    return data as MonthsWithPayments[];
  } catch (error) {
    return { error: JSON.stringify(error) };
  }
}

type ListPaymentsPerDayParams = { country: string; month?: number; year?: number; nurseId?: string };

export async function listPaymentsPerDay({
  country,
  month,
  year,
  nurseId,
}: ListPaymentsPerDayParams): Promise<NursePaymentDaySummary[] | { error: string }> {
  try {
    const { data } = await client.get("/v2/nurse-payments/payments-per-day/", {
      params: {
        country,
        month,
        year,
        nurse_id: nurseId,
      },
    });
    return data as NursePaymentDaySummary[];
  } catch (err) {
    return parseError(err);
  }
}

export async function listNursePayments({
  nurseId,
  beginDate,
  endDate,
}: {
  nurseId: string;
  beginDate: string;
  endDate: string;
}): Promise<NursePaymentResponse | { error: string }> {
  try {
    const { data } = await client.get("/v2/nurse-payments/", {
      params: {
        nurse_id: nurseId,
        start_date: beginDate,
        end_date: endDate,
      },
    });
    return data as NursePaymentResponse;
  } catch (err) {
    return parseError(err);
  }
}

export async function createNursePayments(params: {
  nurseId: string;
  date: string;
  amount: number;
  name: string;
}): Promise<AxiosResponse<any> | { error: string }> {
  try {
    const { nurseId, ...rest } = params;
    const res = await client.post("/v2/nurse-payments/", {
      nurse_id: nurseId,
      ...rest,
    });
    return res;
  } catch (err) {
    return parseError(err);
  }
}

export async function editNursePayments(
  id: string,
  body: Partial<NursePayment>,
): Promise<AxiosResponse<any> | { error: string }> {
  try {
    const res = await client.patch(`/v2/nurse-payments/${id}/`, body);
    return res;
  } catch (err) {
    return parseError(err);
  }
}

export async function getNurseMonthSummary(
  nurseId: string,
  month: number,
  year: number,
): Promise<NurseMonthSummary | { error: string }> {
  try {
    const res = await client.get("/v2/nurse-payments/month-summary/", {
      params: {
        nurse_id: nurseId,
        month: month,
        year: year,
      },
    });
    return res.data as NurseMonthSummary;
  } catch (err) {
    return parseError(err);
  }
}

export async function deleteNursePayment(id: string): Promise<AxiosResponse<any> | { error: string }> {
  try {
    const res = await client.delete(`/v2/nurse-payments/${id}/`);
    return res;
  } catch (err) {
    return parseError(err);
  }
}
