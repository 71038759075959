import { AxiosResponse } from "axios";

import api from "~/utils/api/api";

const url = "https://xmdi.cl/shorten-url";

class ShortUrlService {
  async create(longUrl: string): Promise<AxiosResponse> {
    const data = { long_url: longUrl };
    return api.post(`${url}/`, data);
  }
}

const shortUrlService = new ShortUrlService();

export default shortUrlService;
