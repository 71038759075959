import React, { useEffect, useState } from "react";
import shortUrlService from "~/utils/api/v1/shortUrlService";

import { isProd } from "~/utils/environment";

type AppointmentPendingPaymentLinkProps = {
  appointmentId: string;
  isLoading: boolean;
  className?: string;
};

function AppointmentPendingPaymentLink({ appointmentId, isLoading: isLoadingProps, className }: AppointmentPendingPaymentLinkProps) {
  const [stateLoading, setStateLoading] = useState<boolean>(false);
  const [shortLink, setShortLink] = useState<string | undefined>(undefined);
  const link = `https://${isProd ? "" : "staging."}examedi.com/pago-pendiente/${appointmentId}`;

  const createShortUrl = async (longUrl: string) => {
    setStateLoading(true);
    const { data } = await shortUrlService.create(longUrl);

    if (data) {
      setShortLink(data.short_url as string);
    }
    setStateLoading(false);
  };

  useEffect(() => {
    if (appointmentId) {
      createShortUrl(link);
    }
  }, [appointmentId]);

  const isLoading = isLoadingProps || stateLoading;

  return (
    <div className={className}>
      {isLoading && (
        <div className="flex items-center">
          <div className="h-4 w-40 bg-gray-200 animate-pulse rounded mr-2"></div>
          <div className="h-4 w-96 bg-gray-200 animate-pulse rounded mr-2"></div>
          <div className="h-8 w-16 bg-gray-200 animate-pulse rounded"></div>
        </div>
      )}
      {!isLoading && shortLink && (
        <div className="flex flex-col justify-between">
          <label className="text-g3 font-medium text-base">Link para pago pendiente: </label>
          <div className="flex items-center">
            <a href={shortLink} target="_blank" rel="noreferrer">{shortLink}</a>
            <button
              className="ml-2 px-2 py-1 text-sm bg-examedi-blue-strong text-white rounded hover:bg-examedi-blue-dark"
              onClick={async () => {
                await navigator.clipboard.writeText(shortLink);
                const button = document.activeElement as HTMLButtonElement;
                const originalText = button.innerText;
                button.innerText = "¡Copiado!";
                button.disabled = true;
                setTimeout(() => {
                  button.innerText = originalText;
                  button.disabled = false;
                }, 2000);
              }}
            >
              Copiar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AppointmentPendingPaymentLink;
