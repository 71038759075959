import React from "react";

import { AppointmentPatientV2 } from "~/utils/interfaces/AppointmentPatient";

interface TotalpackPatientInfoProps {
  appointmentPatient: AppointmentPatientV2;
}

const TotalpackPatientInfo = ({ appointmentPatient }: TotalpackPatientInfoProps) => {
  return (
    <div className="w-full mt-5 inline-block bg-gray-100">
      <h3>Totalpack</h3>
      <p
        className={
          !appointmentPatient.active
            ? "text-gray-400"
            : appointmentPatient.totalpack_request?.status !== "connected-successfully"
            ? "text-red-500"
            : ""
        }
      >
        <p className="font-bold">Estado: </p>
        {appointmentPatient.totalpack_request?.status === "without-connection" && "Sin conexión"}
        {appointmentPatient.totalpack_request?.status === "connected-successfully" && "Conectado correctamente"}
        {appointmentPatient.totalpack_request?.status === "valuation-error" && "Error de valoración"}
        {appointmentPatient.totalpack_request?.status === "payment-confirmation-error" &&
          "Error de confirmación de pago"}
        {appointmentPatient.totalpack_request?.status === "closed-appointment" && "Cita cerrada"}
      </p>
      <p
        className={
          !appointmentPatient.active
            ? "text-gray-400"
            : !["connected-successfully", "closed-appointment"].includes(
                appointmentPatient.totalpack_request?.status || "",
              )
            ? "text-red-500"
            : ""
        }
      >
        <p className="font-bold">Mensaje: </p>
        {appointmentPatient.totalpack_request?.message}
      </p>
      {appointmentPatient.copago !== null && <p>Último copago calculado: {appointmentPatient.copago}</p>}
    </div>
  );
};

export default TotalpackPatientInfo;
