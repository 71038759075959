import React, { Dispatch, SetStateAction, useState } from "react";

import { ButtonGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import NormalReassign from "~/components/Appointments/Reassign/NormalReassign";
import SuperadminReassign from "~/components/Appointments/Reassign/SuperadminReassign";

interface Props {
  id: string;
  beginDate: string;
  setError: Dispatch<SetStateAction<string>>;
}

const ReassignAppointment = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<"select" | "normal" | "superadmin">("select");

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    setType("select");
  };

  const handleOpenClose = (newState: boolean): void => {
    if (newState) {
      handleOpen();
    } else {
      handleClose();
    }
  };

  return (
    <>
      <Button
        variant="text"
        color="primary"
        size="small"
        onClick={handleOpen}
      >
        Re-asignar
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          handleOpenClose(false);
        }}
        className="-z-10"
      >
        <DialogTitle>Re-asignar tomar de muestra</DialogTitle>
        {type === "select" ? (
          <DialogContent>
            <ButtonGroup
              variant="outlined"
              size="large"
              color="primary"
              aria-label="large contained primary button group"
            >
              <Button
                value="normal"
                onClick={() => {
                  setType("normal");
                }}
              >
                Normal
              </Button>
              <Button
                value="superadmin"
                onClick={() => {
                  setType("superadmin");
                }}
              >
                Super-admin
              </Button>
            </ButtonGroup>
          </DialogContent>
        ) : type === "normal" ? (
          <NormalReassign
            setError={(error) => {
              props.setError(error);
            }}
            setOpen={(newState) => {
              handleOpenClose(newState);
            }}
            id={props.id}
          />
        ) : (
          <SuperadminReassign
            setError={(error) => {
              props.setError(error);
            }}
            setOpen={(newState) => {
              handleOpenClose(newState);
            }}
            id={props.id}
            beginDate={props.beginDate}
          />
        )}
      </Dialog>
    </>
  );
};

export default ReassignAppointment;
