import React, { Dispatch, forwardRef, SetStateAction, useEffect, useImperativeHandle, useRef, useState } from "react";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { AxiosError } from "axios";
import Swal from "sweetalert2";

import { useScrollTop } from "~/hooks";
import appointmentService from "~/utils/api/v1/appointmentService";
import fetch from "~/utils/api/v1/fetchHelper";
import * as places from "~/utils/comunas/places.json";
import { Address } from "~/utils/interfaces/Address";
import { AppointmentV2 } from "~/utils/interfaces/Appointment";

interface Props {
  id: string;
  appointmentData: AppointmentV2;
  setAppointmentData: Dispatch<SetStateAction<AppointmentV2 | undefined>>;
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
}

// default filter fixes unexpected crash in production
const regiones = Object.keys(places["Chile"]).filter((key) => key !== "default");
const estados = Object.keys(places["México"]).filter((key) => key !== "default");

const AppointmentAddressInput = forwardRef(function AppointmentAddressInput(props: Props, ref: any): JSX.Element {
  const [address, setAddress] = useState<Address>({
    address: "",
    region: regiones[0],
    comuna: places["Chile"][regiones[0]][0],
    country: "Chile",
  });
  const [isApartment, setIsApartment] = useState<boolean>(props.appointmentData.target_residence_type === "apartment");
  const [aptNumber, setAptNumber] = useState<string>(props.appointmentData.target_apartment_number);
  const [additionalInfo, setAdditionalInfo] = useState<string>(
    props.appointmentData.target_address_additional_info || "",
  );

  useEffect(() => {
    const addressSplit = props.appointmentData.target_address.split(",");

    let comuna, region, targetAddressCountry, shortAddress;

    if (!["Chile", "México", "Mexico"].includes(addressSplit.slice(-1)[0].trim())) {
      // country not included in address
      [comuna, region] = addressSplit.slice(-2);
      shortAddress = addressSplit.slice(0, -2).join(",");
      targetAddressCountry = undefined;
    } else {
      [comuna, region, targetAddressCountry] = addressSplit.slice(-3);
      shortAddress = addressSplit.slice(0, -3).join(",");
    }

    const country = (targetAddressCountry || props.appointmentData.country).trim();
    const fixedCountry = country === "Mexico" ? "México" : country;

    setAddress({
      address: shortAddress || "",
      region: region ? region.trim() : regiones[0],
      comuna: comuna ? comuna.trim() : places[country.trim()][regiones[0]][0],
      country: fixedCountry,
    });
  }, [props.appointmentData.target_address]);

  useImperativeHandle(ref, () => ({
    getState: () => {
      return {
        address,
        isApartment,
        aptNumber,
        additionalInfo,
      };
    },
    validate: validate,
  }));

  const validate = (): string[] => {
    const errors: string[] = [];
    if (address.address === "") {
      errors.push("Dirección es requerida");
    }
    if (isApartment && aptNumber === "") {
      errors.push("Número de apartamento es requerido");
    }
    return errors;
  };

  const updateRegion = (e: any) => {
    setAddress({
      ...address,
      region: e.target.value,
      comuna: places[address.country || "Chile"][e.target.value][0],
    });
  };

  return (
    <>
      <div className="flex flex-row flex-nowrap items-center">
        <Typography component="div">
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
          >
            <Grid item>Casa</Grid>
            <Grid item>
              <Switch
                color="primary"
                value={isApartment}
                onChange={(e) => {
                  setIsApartment(e.target.checked as boolean);
                }}
                checked={isApartment}
              />
            </Grid>
            <Grid item>Departamento</Grid>
          </Grid>
        </Typography>
      </div>
      <div className="flex flex-row flex-nowrap items-center">
        <TextField
          id="target_address"
          onChange={(e) => setAddress({ ...address, address: e.target.value })}
          value={address.address}
          helperText="Direccion"
          fullWidth
        />
      </div>
      <div className="flex flex-row flex-nowrap items-center">
        <FormControl className="m-2 mx-8">
          <Select
            value={address.region}
            onChange={(e) => updateRegion(e)}
          >
            {address.country === "Chile" &&
              regiones?.map((region) => (
                <MenuItem
                  key={region}
                  value={region}
                >
                  {region}
                </MenuItem>
              ))}

            {address.country === "México" &&
              estados?.map((estado) => (
                <MenuItem
                  key={estado}
                  value={estado}
                >
                  {estado}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{address.country === "México" ? "Estado" : "Región"}</FormHelperText>
        </FormControl>
        <FormControl className="m-2 mx-8">
          <Select
            value={address.comuna}
            onChange={(e) => setAddress({ ...address, comuna: e.target.value as string })}
          >
            {!!address.country &&
              places[address.country][address.region]?.map((municipio) => (
                <MenuItem
                  key={municipio}
                  value={municipio}
                >
                  {municipio}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{address.country === "México" ? "Municipio" : "Comuna"}</FormHelperText>
        </FormControl>
      </div>
      <div className="flex flex-row flex-nowrap items-center">
        {isApartment && (
          <div className="flex flex-row flex-nowrap items-center">
            <TextField
              value={aptNumber}
              onChange={(e) => setAptNumber(e.target.value)}
              helperText="Número de departamento"
            />
          </div>
        )}
        <TextField
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
          helperText="Información adicional"
        />
      </div>
    </>
  );
});

const ChangeAppointmentAddress = forwardRef(function ChangeAppointmentAddress(props: Props, ref) {
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<any[]>([]);
  const addressDataRef = useRef<any>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openDialog: () => {
      handleOpen();
    },
  }));

  const handleChangeAddress = async () => {
    const vErrors = addressDataRef.current.validate();
    if (vErrors.length > 0 || !addressDataRef.current) {
      setValidationErrors(vErrors);
      useScrollTop();
      return;
    }

    await fetch(setLoading, props.setError, async () => {
      const addressData = addressDataRef.current.getState();
      const processedAddress = {
        target_address: `${addressData.address.address}, ${addressData.address.comuna}, ${addressData.address.region}, ${addressData.address.country}`,
        target_residence_type: addressData.isApartment ? "apartment" : "house",
        target_apartment_number: addressData.isApartment ? addressData.aptNumber : "",
        target_address_additional_info: addressData.additionalInfo,
        comuna: addressData.address.comuna,
        region: addressData.address.region,
      };
      try {
        const req = await appointmentService.changeAddress(props.id, processedAddress);
        const { target_address, target_residence_type, target_apartment_number, target_address_additional_info } =
          req.data;
        props.setAppointmentData({
          ...props.appointmentData,
          target_address,
          target_residence_type,
          target_apartment_number,
          target_address_additional_info,
        });
        await Swal.fire({
          icon: "success",
          title: "Cambio exitoso",
          text: "Se ha cambiado la dirección de la cita.",
        });
      } catch (err) {
        await Swal.fire({
          icon: "error",
          title: "Error al cambiar la dirección",
          text: err?.response?.data?.err,
        });
      }
    });
    handleClose();
  };

  return (
    <>
      <Button
        className="border-l border-solid border-blue-500 border-opacity-50 rounded-tl-none rounded-bl-none"
        variant="text"
        color="primary"
        size="small"
        onClick={handleOpen}
      >
        Cambiar dirección
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Cambiar dirección</DialogTitle>
        <DialogContent>
          {loading ? (
            <div className="flex justify-center items-center p-12">
              <CircularProgress />
            </div>
          ) : (
            <DialogContentText>
              <>
                {validationErrors.length > 0 && (
                  <Typography
                    variant="body2"
                    color="error"
                  >
                    {validationErrors.map((error) => (
                      <div key={error}>{error}</div>
                    ))}
                  </Typography>
                )}
              </>
              <AppointmentAddressInput
                ref={addressDataRef}
                id={props.id}
                appointmentData={props.appointmentData}
                setAppointmentData={props.setAppointmentData}
                setError={props.setError}
              />

              <div className="flex justify-center items-center p-4">
                <ButtonGroup
                  variant="outlined"
                  color="primary"
                >
                  <Button onClick={handleChangeAddress}>Cambiar dirección</Button>
                </ButtonGroup>
              </div>
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
});

export default ChangeAppointmentAddress;
