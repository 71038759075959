import React, { Dispatch, Fragment, SetStateAction, useMemo, useState } from "react";

// components
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { AxiosError } from "axios";
import { navigate } from "gatsby";
import moment from "moment";
import Swal from "sweetalert2";

import BoletaOrFactura from "~/components/Appointments/BoleraOrFactura";
import ChangeAppointmentAddress from "~/components/Appointments/ChangeAddress";
import ReassignAppointment from "~/components/Appointments/Reassign/ReassingAppointment";
import appointmentService from "~/utils/api/v1/appointmentService";
import { TIMEZONE_TO_COUNTRY } from "~/utils/data/constants";
import { AppointmentV2, Payment } from "~/utils/interfaces/Appointment";
import { AppointmentPatientV2 } from "~/utils/interfaces/AppointmentPatient";
import contactChannelMapper from "~/utils/mappers/contactChannelMapper";
import { numberWithPeriods } from "~/utils/numbers";

interface AppointmentInfoProps {
  appointmentId: string;
  appointment: AppointmentV2;
  setAppointment: Dispatch<SetStateAction<AppointmentV2 | undefined>>;
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
  cancelComponentRef: React.MutableRefObject<any>;
  payments: Payment[];
  appointmentPatients: AppointmentPatientV2[];
}

const AppointmentInfo = ({
  appointmentId,
  appointment,
  setAppointment,
  setError,
  cancelComponentRef,
  payments,
  appointmentPatients,
}: AppointmentInfoProps) => {
  const [copago, setCopago] = useState<number | string>("No calculado");

  const timezone = useMemo(() => {
    if (appointment && appointment.safe_timezone) {
      return TIMEZONE_TO_COUNTRY[appointment.safe_timezone];
    }
    return "";
  }, [appointment]);

  const displayableBeginDate = (): string => {
    if (!appointment) {
      return "";
    }
    return moment(appointment.local_begin_date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm");
  };

  const handleCancelButton = () => {
    cancelComponentRef?.current?.openDialog();
  };

  const handleGetCopago = async () => {
    try {
      const res = await appointmentService.totalpackValuation(appointmentId);
      if (res.data) {
        setCopago(res.data.data.copago);
        await Swal.fire({
          icon: "success",
          title: "Copago obtenido con éxito",
        });
        window.location.reload();
      }
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al calcular el copago. Para más detalle, ver los errores de Totalpack en el paciente",
      });
      window.location.reload();
    }
  };

  const services = [
    ...appointmentPatients.map((ap) => ap.appointment_patient_services.map((aps) => aps.lab_service.id)).flat(),
    ...appointmentPatients
      .map((ap) => ap.appointment_patient_item_packs.map((app) => app.pack.items.map((item) => item)))
      .flat(2),
  ];

  function parseDiscount(discount: string | undefined, method: string) {
    try {
      if (method === "percentage") {
        return `${parseFloat(discount || "0") * 100}%`;
      } else if (method === "amount") {
        return `$${parseFloat(discount || "0")}`;
      }
    } catch (e) {
      console.error(e);
    }
    return discount;
  }

  return (
    <Fragment>
      <div className="bg-gray-200 rounded-lg p-4 inline-block md:flex-basis-45 flex-grow">
        <div className="flex flex-row justify-between items-center">
          <h3>Cita</h3>
          <ButtonGroup
            color="primary"
            variant="text"
            size="small"
          >
            <Button onClick={handleCancelButton}> Reembolsar y cancelar</Button>
            <Button
              onClick={() => {
                navigate(`/appointment/${appointmentId}/reschedule/`, {
                  state: { services },
                });
              }}
            >
              {" "}
              Re-agendar{" "}
            </Button>
            <ReassignAppointment
              id={appointmentId}
              setError={setError}
              beginDate={moment(appointment.local_begin_date, "YYYY-MM-DD HH:mm:ss").format()}
            />
            <ChangeAppointmentAddress
              id={appointmentId}
              appointmentData={appointment}
              setAppointmentData={setAppointment}
              setError={setError}
            />
          </ButtonGroup>
        </div>
        <BoletaOrFactura
          id={appointmentId}
          isFactura={appointment?.is_factura || false}
          setError={setError}
        />
        <p className="my-2">
          <span className="font-bold">Hora: </span> {displayableBeginDate()} - {timezone}
        </p>
        <p className="my-2">
          <span className="font-bold">Dirección: </span> {appointment?.target_address}
        </p>
        <p className="my-2">
          <span className="font-bold">Tipo de residencia: </span>
          {appointment?.target_residence_type === "apartment" ? "Departamento" : "Casa"}
        </p>
        {appointment?.target_residence_type === "apartment" ? (
          <p className="my-2">
            <span className="font-bold">Número de departamento: </span> {appointment?.target_apartment_number}
          </p>
        ) : (
          ""
        )}
        <p className="my-2">
          <span className="font-bold">Piso / Oficina / Departamento: </span>
          {appointment?.target_apartment_number || "No especifica"}
        </p>
        <p className="my-2">
          <span className="font-bold">Canal de contacto: </span> {contactChannelMapper(appointment?.contact_channel || "")}
        </p>
        <p className="my-2">
          <span className="font-bold">Información adicional: </span> {appointment?.target_address_additional_info}
        </p>
        <div>
          <p className="font-bold my-2">Información de Cupón: </p>
          {appointment?.referrer_snapshot.length === 0 && <div>no se aplicó un cupón</div>}
          {appointment?.referrer_snapshot.length !== 0 && (
            <div>
              <p className="my-2">Código: {appointment.referrer_snapshot[0].referrer}</p>
              {appointment.referrer_snapshot[0].method === "percentage" && (
                <p className="my-2">
                  Porcentaje de descuento:{" "}
                  {`${appointment.referrer_snapshot[0].discount * 100}%` ||
                    parseDiscount(
                      appointment.referrer_snapshot[0].snapshot_values.discount,
                      appointment.referrer_snapshot[0].method,
                    )}
                </p>
              )}
              {appointment.referrer_snapshot[0].method === "amount" && (
                <p className="my-2">
                  Monto de descuento:{" "}
                  {`$${appointment.referrer_snapshot[0].discount}` ||
                    parseDiscount(
                      appointment.referrer_snapshot[0].snapshot_values.discount,
                      appointment.referrer_snapshot[0].method,
                    )}
                </p>
              )}
              <p className="my-2">Monto mínimo: {appointment.referrer_snapshot[0].snapshot_values.minimum_purchase}</p>
            </div>
          )}
        </div>
        <p className="font-bold my-2">Pagos</p>
        {payments?.map((payment, index: number) => {
          if (payment.status === "Payment Successful") {
            if (payment.payment_gateway) {
              return (
                <p key={index} className="my-2">
                  Monto pagado con {payment.payment_gateway}: ${numberWithPeriods(payment.amount)}
                </p>
              );
            } else {
              return <p key={payment.id} className="my-2">Sin información de método de pago: ${numberWithPeriods(payment.amount)}</p>;
            }
          }
        })}
        {appointment?.last_mile && (
          <React.Fragment>
            <h5>Última milla</h5>
            <p className="my-2">
              <span className="font-bold">Número de folio: </span> {appointment.last_mile.folio}
            </p>
            <p className="my-2">
              <span className="font-bold">Monto extra: </span> ${numberWithPeriods(appointment.last_mile.extra_amount)}
            </p>
          </React.Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default AppointmentInfo;
