import { SupportedCountry } from "~/utils/constants/places";
import { Address } from "~/utils/interfaces/Address";
import { Patient } from "~/utils/interfaces/AppointmentPatient";
import { FullDate } from "~/utils/interfaces/dates";
import { ItemToAdd } from "~/utils/interfaces/Items";
import { NurseName, NurseNameSerializer, NurseProfile, NurseProfileCamelCase } from "~/utils/interfaces/Nurse";
import { PackToAdd } from "~/utils/interfaces/Pack";
import { SchedulingPatient } from "~/utils/interfaces/Patient";
import { NewTimeBlock } from "~/utils/interfaces/Timeblock";
import TimelineEvent from "~/utils/interfaces/TimelineEvent";
import { User } from "~/utils/interfaces/User";

export enum ReceiptType {
  DELIVERY = "delivery",
  LAB_WORK = "lab-work",
  EXAMEDI_VACCINE_WORK = "examedi-vaccine-work",
  EXAMEDI_ECG_WORK = "examedi-ecg-work",
  EXAMEDI_NEUROBIONTA_WORK = "examedi-neurobionta-work",
  EXAMEDI_ANTIGEN_WORK = "examedi-antigen-work",
  EXAMEDI_ALLERGIES_WORK = "examedi-allergies-work",
  VOUCHER = "voucher",
  FONASA_VOUCHER = "fonasa-voucher",
  TOTALPACK_VOUCHER = "totalpack-voucher",
  KINESOLOGY = "kinesiology",
}

export interface Payment {
  id?: string | null;
  created_at?: string;
  paid_at?: string;
  amount: number;
  status: string;
  payment_gateway: string;
  method: string;
  gateway_identifier?: string | null;
  bank_name?: string | null;
  document_number?: string | null;
  receipt_image?: Blob | null;
}

export interface DetailedAppointment {
  id: string;
  created_at: string;
  updated_at: string;
  begin_date: string;
  country: string;
  local_work_period_max_lateness: string;
  work_period_max_lateness: string;
  services: Array<Object>;
  service_names: string;
  nurse: {
    user: User;
  };
  patient: User;
  finished: boolean;
  is_factura: boolean;
  status: string;
  patients: Array<User>;
  timeline_status: Array<string>;
  target_address: string;
  target_lat: string;
  target_lng: string | null;
  target_address_additional_info: string;
  target_apartment_number: string;
  target_residence_type: string;
  patient_first_name: string;
  patient_last_name: string;
  patient_full_name: string;
  displayable_begin_date: string;
  timeline_events: Array<any>;
  timeline_events_tags?: Array<string>;
  time_conflict: boolean;
  sales_source: string;
  block_taken: boolean;
  payments: Array<Payment>;
  totalpack_request_status: string;
  totalpack_request_message: string;
  contact_channel: string;
  is_operative: boolean;
  last_mile: {
    appointment: string;
    created_at: string;
    updated_at: string;
    folio: string;
    extra_amount: number;
    id: string;
  } | null;
  section_origin: string;
  comuna: string;
  safe_timezone?: string;
}
export interface ReferrerEvent {
  referrer: string;
  discount: number;
  method: string;
  snapshot_values: {
    discount: string;
    minimum_purchase: string;
    expiration_time: string;
  };
}

export interface AppointmentV2 {
  id: string;
  local_begin_date: string;
  local_work_period_max_lateness: string;
  target_address: string;
  nurse: string;
  section_origin: string;
  country: string;
  region?: string;
  safe_timezone: string;
  comuna: string;
  is_factura: boolean;
  target_residence_type: string;
  target_apartment_number: string;
  contact_channel: string | null;
  address_line_1?: string;
  target_address_additional_info: string;
  finished: boolean;
  status: string;
  sales_source: string;
  client_origin: string | null;
  is_operative: boolean;
  appointment_patients: string[];
  appointment_patients_info: SchedulingPatient[];
  last_mile: {
    folio: string;
    extra_amount: number;
  } | null;
  timeline_events: AppointmentTimelineEvent[];
  totalpack_request: {
    status: string;
    message: string;
  } | null;

  payments: string[];
  receipts: string[];
  deals: { grouped_orden_medica: string }[];
  referrer_snapshot: ReferrerEvent[];
  patient: Patient;
  nurse_profile: NurseProfile;
  is_on_auction: boolean;
  auction_id: string | null;
}

export interface CamelCaseAppointment {
  addressLine1: string;
  clientOrigin: string | null;
  colony: string;
  comuna: string;
  country: string;
  fastHours: number;
  fingerprintRequired: boolean;
  finished: boolean;
  id: string;
  localBeginDate: string;
  localWorkPeriodMaxLateness: string | null;
  nurseProfile?: NurseProfileCamelCase;
  patient: Patient;
  payments: string[];
  region: string;
  salesSource: string;
  sectionOrigin: string;
  serviceNames: string;
  status: string;
  targetAddress: string;
  targetAddressAdditionalInfo: string;
  targetApartmentNumber: string;
  targetLat: number;
  targetLng: number;
  targetResidenceType: string;
  targetStreetNumber: string;
  timelineEvents: AppointmentTimelineEvent[];
  uniqueCategoriesNames: string[];
  uniqueItemCategories: string[];
  uniqueItemNames: string[];
  urls: { detail: string };
  zipCode: string;
}

export interface PaymentEventLog {
  paid: number;
  cost_services: number;
  up_to_date: boolean;
  patient_info?: {
    age: number;
    patient_id: string;
    full_name: string;
  };
  action?: string;
  item_id?: string;
  pack_id?: string;
  item_info?: { name: string };
  pack_info?: { name: string };
}
export interface AppointmentTimelineEvent {
  id: string;
  tag: string;
  owner: {
    fullname: string;
  } | null;
  created_at: string;
  event_info: Record<string, string> | PaymentEventLog;
}

export interface Appointment {
  id: string;
  begin_date: string;
  local_begin_date: string;
  finished: boolean;
  nurse?: NurseName;
  patient: Patient;
  patients: Patient[];
  first_active_patient: Patient;
  service_names: string;
  status: string;
  target_address: string;
  target_address_additional_info: string;
  target_apartment_number: string;
  target_lat: number | null;
  target_lng: number | null;
  target_residence_type: "house" | "apartment";
  work_period_max_lateness: string;
  timeline_events: TimelineEvent[];
  time_conflict: boolean;
  block_taken: boolean;
}

export interface TotalPackAppointment
  extends Pick<
    Appointment,
    "id" | "service_names" | "target_address" | "time_conflict" | "patient" | "patients" | "local_begin_date"
  > {
  totalpack_request_status: string;
  totalpack_request_message: string;
}

export interface OperativeAppointmentData {
  id: string;
  created_at: string;
  name: string;
  base_price: number;
  person_price: number;
  lead: string;
  appointment: string;
  seller: {
    full_name: string;
  };
  company: {
    id: string;
    name: string;
    document_id: string | null;
    contact_name: string;
    contact_email: string;
    contact_phone: string;
  };
  payment: {
    id: string;
    created_at: string;
    amount: number;
    status: string;
    paid_at: string | null;
    payment_gateway: string;
    gateway_identifier: string;
    method: string | null;
  };
  appointment_nurses: {
    id: string;
    gross_ht_payment: number;
    appointment: string;
    nurse: {
      country: string;
      profile_picture: string;
      user: {
        annotation: string;
        date_of_birth: string;
        document_number: string;
        document_type: string;
        email: string;
        first_name: string;
        full_name: string;
        gender: string;
        id: string;
        insurance: string;
        insurer: string;
        last_name: string;
        phone: string;
        rut: string;
      };
    };
  }[];
}

export interface UploadDoctorData {
  name?: string;
  id?: string;
  mail?: string;
  appointment_patient_id: string;
}

export interface Receipt {
  id: string;
  file_link: string;
  receipt_type: string;
  created_at: string;
}

export interface Result {
  id: string;
  file_link: string;
  created_at: string;
  lab_name?: string;
  categories: string[];
}

export interface Comment {
  id: string;
  comment: string;
  created_at: string;
  user: {
    fullname: string;
  };
}

export type GetAvailabilities = {
  appointmentId?: string;
  localePath?: string;
  itemIds?: string[];
  isFonasa?: boolean;
  address?: Address | null;
  includeSourceOnly?: boolean;
  fromDate: string;
};

export type AddDocumentResponse = {
  msg: string;
  url: string;
};

export type RetrocompatibleAvailabilitiesPayload = Record<FullDate, NewTimeBlock[]>;

export default Appointment;

export type AddServicesPayload = {
  packs?: PackToAdd[];
  items?: ItemToAdd[];
  created_from: string;
};

export type BaseAppointmentType = {
  id: string;
  address_line_1: string;
  colony: string;
  comuna: string;
  country: SupportedCountry;
  fingerprint_required: boolean;
  local_begin_date: string;
  local_work_period_max_lateness: string;
  region: string;
  target_address: string;
  target_address_additional_info: string;
  target_apartment_number: string | null;
  target_lat: number;
  target_lng: number;
  target_street_number: string | null;
  target_residence_type: string;
  sales_source: string;
  zip_code: string;
  section_origin: string;
  client_origin: string | null;
  service_names: string;
  finished: boolean;
  nurse_profile: NurseNameSerializer;
};

export type UpdateAppointment = {
  appointmentId: string;
  data: Partial<Appointment>;
};
