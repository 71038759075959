/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import { InputLabel, MenuItem, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import ApartmentIcon from "@material-ui/icons/Apartment";
import HomeIcon from "@material-ui/icons/Home";
import { AxiosError } from "axios";
import moment from "moment";
import Swal from "sweetalert2";

import HTCard from "~/components/Appointments/Detail/HTCard";
import alliancesService from "~/utils/api/v1/alliancesService";
import appointmentService from "~/utils/api/v1/appointmentService";
import { appointmentAPI } from "~/utils/api/v2";
import { newSalesSources } from "~/utils/constants/appointment";
import { DEFAULT_TIMEZONE, TIMEZONE_TO_COUNTRY } from "~/utils/data/constants";
import { AppointmentV2 } from "~/utils/interfaces/Appointment";
import { AppointmentPatientV2 } from "~/utils/interfaces/AppointmentPatient";

type HeaderProps = {
  appointmentId: string;
  appointment: AppointmentV2;
  setAppointment: Dispatch<SetStateAction<AppointmentV2 | undefined>>;
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
  appointmentPatients: AppointmentPatientV2[];
};

const Header = ({ appointmentId, appointment, setAppointment, setError, appointmentPatients }: HeaderProps) => {
  const [salesSourcesList, setSalesSourcesList] = useState<string[]>([]);

  const timezone = useMemo(() => {
    if (appointment && appointment.safe_timezone) {
      // @ts-expect-error
      return TIMEZONE_TO_COUNTRY[appointment.safe_timezone];
    }
    return DEFAULT_TIMEZONE;
  }, [appointment]);

  const mainUserFullName = useMemo(() => {
    const activeAppPatients = appointmentPatients.filter((patient) => patient.active);
    if (activeAppPatients.length > 0) {
      const patient = activeAppPatients[0].patient;
      const firstName = patient.first_name;
      const secondName = patient.second_name;
      const lastName = patient.last_name;
      const secondLastName = patient.second_last_name;
      return `${firstName} ${secondName} ${lastName} ${secondLastName}`.replace(/ +(?= )/g, "");
    }
    return "";
  }, [appointmentPatients]);

  const getSalesSources = async () => {
    try {
      const res = await appointmentService.fetchSalesSources();
      const clientsRes = await alliancesService.fetchClients();
      const oldSaleSources: string[] = res.data.data;
      const clients = clientsRes.data;
      const clientsSalesSource = clients.map((client) => client.sales_source);
      const mergedSaleSources = new Set(oldSaleSources.concat(newSalesSources).concat(clientsSalesSource));
      const mergedSalesSourceSorted = Array.from(mergedSaleSources).sort((a, b) => {
        return a.localeCompare(b);
      });
      setSalesSourcesList([...mergedSalesSourceSorted]);
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al conseguir algunos datos de la cita",
        allowOutsideClick: false,
      });
    }
  };

  const salesSourceChangeConfirm = async (salesSource: string) => {
    try {
      const check = await Swal.fire({
        title: "¿Quieres cambiar el Sales Source a " + salesSource + "?",
        icon: "warning",
        showCancelButton: true,
      });
      if (!check.isConfirmed) {
        return;
      }
      setAppointment({ ...appointment, sales_source: salesSource });
      await appointmentAPI.patch(appointmentId, {
        sales_source: salesSource,
      });
      await Swal.fire({
        title: "Hecho",
      });
    } catch (err) {
      setError(err);
    }
  };

  const onChangeSalesSource = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const salesSource = event.target.value;
    salesSourceChangeConfirm(salesSource);
  };

  const displayableBeginDate = (): string => {
    return moment(appointment.local_begin_date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm");
  };

  const displayableEndHour = (): string => {
    return moment(appointment.local_work_period_max_lateness, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
  };

  const displaySectionOrigin = (): JSX.Element => {
    const sectionOriginMap = {
      "self-service": "self-service (examedi.com)",
      betty: "betty (offliner)",
      "laas-link": "laas-link (laas paciente)",
      "laas-platform": "laas-platform (laas cliente)",
      "medical-order": "medical-order (a través de orden médica en Examedi.com)",
      sdk: "sdk",
      "assisted-scheduling": "assisted-scheduling (agendamiento asistido en betty)",
      "manual-confirm": "manual-confirm (manual confirm origen desconocido)",
    };
    const sectionOrigin = sectionOriginMap[appointment.section_origin] || appointment.section_origin;
    return <p className="font-bold">{sectionOrigin}</p>;
  };

  const contactChannel = useMemo(() => {
    const contactChannelMap = {
      whatsapp: "Whatsapp",
      alliance: "Alianza",
      intercom: "Intercom",
      medical_representative: "Visitador médico",
      call_center: "Call center",
      marketing_campaing: "Campaña de marketing",
    };
    const mappedContactChannel = contactChannelMap[appointment.contact_channel] || appointment.contact_channel;
    return <p className="font-bold">{mappedContactChannel}</p>;
  }, [appointment.contact_channel]);

  const displayChangeSalesSource = () => {
    return (
      <FormControl className="w-fit">
        <InputLabel id="changeSalesSource">Sales Source</InputLabel>
        <Select
          id="change-sales-soruce"
          labelId="change-sales-source"
          style={{ marginTop: 25 }}
          label="Sales Source"
          value={appointment?.sales_source}
          onChange={onChangeSalesSource}
        >
          {salesSourcesList.map((salesSource) => (
            <MenuItem
              key={salesSource}
              value={salesSource}
            >
              {salesSource}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const displayDirection = () => {
    if (appointment?.target_residence_type === "apartment" || appointment?.target_apartment_number) {
      return (
        <h4>
          <ApartmentIcon className="text-md mt-1.5" />
          <span>
            {" "}
            <p>{appointment.target_address}</p> - {appointment?.target_apartment_number}
          </span>
        </h4>
      );
    }
    return (
      <h4>
        <span>
          {" "}
          <p>
            <HomeIcon className="text-md mt-1.5" />
            {appointment?.target_address}
          </p>
        </span>
      </h4>
    );
  };

  useEffect(() => {
    getSalesSources();
  }, []);

  return (
    <>
      <div className="w-full flex flex-col justify-between gap-y-8 text-examedi-gray-2">
        <div className="w-full grid grid-cols-4 gap-x-3">
          <div className="col-span-3 leading-3 font-normal">
            <h3>Paciente {mainUserFullName} </h3>
            <h4>
              <span> el día </span>
              <span className="font-bold">
                {displayableBeginDate()} - {displayableEndHour()} - {timezone}
              </span>
            </h4>
            {displayDirection()}
          </div>
          <div className="col-span-1">
            <HTCard nurse={appointment?.nurse_profile} />
          </div>
        </div>
        <div className="w-full grid grid-cols-3">
          <div className="col-span-1 flex items-end justify-center">
            {displayChangeSalesSource()}
            {appointment?.sales_source && (
              <img
                className="object-contain w-16 ml-2.5"
                src={`https://provider-logos-2.s3.us-east-1.amazonaws.com/${appointment.sales_source}.png`}
              />
            )}
          </div>
          <div className="col-span-1 flex flex-col">
            <div>
              Section origin <span className="text-sm"> (cita creada en): </span>
            </div>
            <div>{displaySectionOrigin()}</div>
          </div>
          <div className="col-span-1 flex flex-col">
            <div className="text-sm">Canal de contacto:</div>
            {appointment.contact_channel ? contactChannel : "No especificado"}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
