import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Checkbox, FormControlLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Link } from "gatsby";
import moment from "moment";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import Swal from "sweetalert2";

import api from "~/utils/api/api";
import { receiptAPI } from "~/utils/api/v2";
import { receiptTypes } from "~/utils/constants/appointment";
import { AppointmentV2, Receipt, ReceiptType } from "~/utils/interfaces/Appointment";
import { AppointmentPatientV2 } from "~/utils/interfaces/AppointmentPatient";
import { Laboratory } from "~/utils/interfaces/Laboratory";

interface UploadReceiptProps {
  appointmentId: string;
  labs: Laboratory[];
  beginDate: string;
  uploadingReceipt: boolean;
  appointmentPatients: AppointmentPatientV2[];
  setReceipts: Dispatch<SetStateAction<Receipt[]>>;
  setUploadingReceipt: (value: boolean) => void;
}

const UploadReceipt = ({
  appointmentId,
  labs,
  beginDate,
  uploadingReceipt,
  setUploadingReceipt,
  appointmentPatients,
  setReceipts,
}: UploadReceiptProps): JSX.Element => {
  const [receiptType, setReceiptType] = useState<ReceiptType>(ReceiptType.LAB_WORK);
  const [file, setFile] = useState(null);
  const [validPhotoUploaded, setValidPhotoUploaded] = useState<boolean>(false);
  const [currentPatientId, setCurrentPatientId] = useState<string>("");
  const [selectedLabId, setSelectedLabId] = useState<string | null>(null);
  const [labsHash, setLabsHash] = useState<Record<string, string>>({});

  const uploadReceipt = async (): Promise<void> => {
    if (!file) {
      Swal.fire({
        icon: "error",
        text: "Debes agregar un archivo para la boleta",
        title: "Archivo faltante",
      });
      return;
    }
    if (receiptType === ReceiptType.FONASA_VOUCHER && !selectedLabId) {
      Swal.fire({
        icon: "error",
        text: "Por favor elige el laboratorio que emitió el bono fonasa",
        title: "Hubo un error subiendo el bono fonasa",
      });
      return;
    }
    setUploadingReceipt(true);
    try {
      const formData = new FormData();
      formData.append("receipt_type", receiptType as string);
      formData.append("patient_id", currentPatientId);
      formData.append("files", file);
      formData.append("emission_date", moment(beginDate).format("YYYY-MM-DD"));
      if (selectedLabId) {
        formData.append("lab_id", selectedLabId);
      }

      const receiptCreated = await api.post(`/v1/appointments/${appointmentId}/upload_receipt/`, formData);
      const newReceipt = await receiptAPI.get(receiptCreated.data.data.created_receipt);
      setReceipts((receipts) => [...receipts, newReceipt.data]);

      if (receiptCreated) {
        setUploadingReceipt(false);
        setFile(null);
        Swal.fire({ icon: "success", title: "Boleta registrada" });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: "Hubo un error subiendo la boleta",
      });
      setUploadingReceipt(false);
    }
  };

  const handleChange = (event: any) => {
    const file = event.target.files[0];
    const filename = file.name;
    const parts = filename.split(".");
    const ext = parts[parts.length - 1];
    if (ext !== "pdf") {
      Swal.fire({
        icon: "error",
        title: "El archivo debe ser un PDF",
      });
      return false;
    }

    // if file > 15MB, reject
    if (file.size > 15_000_000) {
      Swal.fire({
        icon: "error",
        title: "El archivo no debe pesar mas de 15MB",
      });
      setValidPhotoUploaded(false);
      return false;
    }
    setFile(file);
    setValidPhotoUploaded(true);
  };

  useEffect(() => {
    setCurrentPatientId(appointmentPatients[0]?.patient.id || "");
  }, [appointmentPatients[0]]);

  useEffect(() => {
    setLabsHash(
      labs.reduce((labAccumulator: Record<string, string>, lab) => {
        labAccumulator[lab.id] = lab.display_name;
        return labAccumulator;
      }, {}),
    );
  }, [labs]);

  if (uploadingReceipt) {
    return <CircularProgress />;
  }

  return (
    <div className="mt-5">
      <div className="my-5" />
      <div className="flex flex-row items-center">
        <div className="flex flex-col">
          <FormControlLabel
            label="Boleta de delivery"
            control={
              <Checkbox
                checked={receiptType === ReceiptType.DELIVERY}
                color="secondary"
                checkedIcon={<MdRadioButtonChecked />}
                icon={<MdRadioButtonUnchecked />}
                onClick={() => {
                  setReceiptType(ReceiptType.DELIVERY);
                  setSelectedLabId(null);
                }}
              />
            }
          />
          <FormControlLabel
            label="Boleta de exámenes"
            control={
              <Checkbox
                checked={receiptType === ReceiptType.LAB_WORK}
                color="secondary"
                checkedIcon={<MdRadioButtonChecked />}
                icon={<MdRadioButtonUnchecked />}
                onClick={() => {
                  setReceiptType(ReceiptType.LAB_WORK);
                  setSelectedLabId(null);
                }}
              />
            }
          />
          <FormControlLabel
            label="Bono de fonasa"
            control={
              <Checkbox
                checked={receiptType === ReceiptType.FONASA_VOUCHER}
                color="secondary"
                checkedIcon={<MdRadioButtonChecked />}
                icon={<MdRadioButtonUnchecked />}
                onClick={() => setReceiptType(ReceiptType.FONASA_VOUCHER)}
              />
            }
          />
        </div>
      </div>
      <input
        type="file"
        accept="application/pdf"
        onChange={handleChange}
      />
      <div className="flex flex-col items-start mt-7.5">
        {receiptType === ReceiptType.FONASA_VOUCHER && (
          <FormControl>
            <InputLabel>Laboratorio</InputLabel>
            <Select
              value={selectedLabId}
              onChange={(e) => {
                setSelectedLabId(e.target.value as string);
              }}
            >
              {labs.map((lab) => {
                return (
                  <MenuItem
                    value={lab.id}
                    key={lab.id}
                  >
                    {labsHash[lab.id]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        <FormControl>
          <InputLabel>Paciente</InputLabel>
          <Select
            value={currentPatientId}
            onChange={(e) => {
              setCurrentPatientId(e.target.value as string);
            }}
          >
            {appointmentPatients.map((appointmentPatient) => {
              return (
                <MenuItem
                  value={appointmentPatient.patient.id}
                  key={appointmentPatient.id}
                >{`${appointmentPatient.patient.first_name} ${appointmentPatient.patient.last_name}`}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>

      <div style={{ marginTop: 30 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={uploadReceipt}
          disabled={!validPhotoUploaded}
        >
          Adjuntar Boleta
        </Button>
      </div>
    </div>
  );
};

interface ReceiptSectionProps {
  id: string;
  appointment: AppointmentV2;
  labs: Laboratory[];
  receipts: Receipt[];
  setReceipts: Dispatch<SetStateAction<Receipt[]>>;
  appointmentPatients: AppointmentPatientV2[];
}

const ReceiptSection = ({ id, appointment, labs, receipts, setReceipts, appointmentPatients }: ReceiptSectionProps) => {
  const [uploadingReceipt, setUploadingReceipt] = useState<boolean>(false);

  const deleteReceipt = async (receiptId: string) => {
    setUploadingReceipt(true);
    try {
      const res = await Swal.fire({
        icon: "warning",
        title: "¿Estás seguro de que quieres eliminar esta boleta?",
        confirmButtonText: "Si, eliminar",
        showCancelButton: true,
        cancelButtonText: "No",
      });
      if (res.value) {
        await receiptAPI.delete(receiptId);
        await Swal.fire({
          icon: "success",
          title: "Boleta eliminada",
        });
      }
      setReceipts((receipts) => {
        return receipts.filter((receipt) => receipt.id !== receiptId);
      });

      setUploadingReceipt(false);
    } catch (err) {
      console.log(err.message);
      setUploadingReceipt(false);
    }
  };

  return (
    <React.Fragment>
      <div className="flex flex-row justify-between items-center">
        <h3>Boleta</h3>
      </div>
      {!!receipts && (
        <UploadReceipt
          appointmentPatients={appointmentPatients}
          appointmentId={id}
          labs={labs}
          beginDate={appointment.local_begin_date?.split("T")[0]}
          uploadingReceipt={uploadingReceipt}
          setReceipts={setReceipts}
          setUploadingReceipt={setUploadingReceipt}
        />
      )}
      <div style={{ marginTop: 20 }}>
        <h4 style={{ marginBottom: 20 }}>Boletas Existentes</h4>
        {receipts.map((receipt: Receipt, i: number) => (
          <div className="p-1.25 p-2.5 text-sm mr-2.5 mb-1.25 flex items-center" key={i}>
            <a
              href={receipt.file_link}
              target="_blank"
              rel="noreferrer"
            >
              Ver
            </a>
            {/* @ts-expect-error */}
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                deleteReceipt(receipt.id);
              }}
              style={{ color: "red", marginLeft: 10, marginRight: 10 }}
            >
              Eliminar
            </Link>
            <p>{receiptTypes[`${receipt.receipt_type}`]}</p>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default ReceiptSection;
