import React, { Dispatch, Fragment, SetStateAction, useMemo, useState } from "react";

import Button from "@material-ui/core/Button";
import { AxiosError } from "axios";
import { decamelizeKeys } from "humps";
import Swal from "sweetalert2";

import MaterialStepper from "~/components/Progression/MaterialStepper";
import api from "~/utils/api/api";
import appointmentService from "~/utils/api/v1/appointmentService";
import medicalOrderService from "~/utils/api/v1/medicalOrderService";
import { appointmentAPI } from "~/utils/api/v2";
import { notifyResultsReadyWhatsapp } from "~/utils/api/v2/appointment";
import { stepsLabels, timelineSteps } from "~/utils/constants/appointment";
import { AppointmentTimelineEvent, AppointmentV2, Payment } from "~/utils/interfaces/Appointment";
import { AppointmentPatientV2 } from "~/utils/interfaces/AppointmentPatient";

interface ActionablesProps {
  appointmentId: string;
  appointment: AppointmentV2;
  setAppointment: Dispatch<SetStateAction<AppointmentV2 | undefined>>;
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  timelineEvents: AppointmentTimelineEvent[];
  setTimelineEvents: Dispatch<SetStateAction<AppointmentTimelineEvent[]>>;
  appointmentPatients: AppointmentPatientV2[];
  payments: Payment[];
}

interface DisplayableButtonsProps {
  appointmentId: string;
  appointment: AppointmentV2;
  setAppointment: Dispatch<SetStateAction<AppointmentV2 | undefined>>;
  timelineEventsTags: string[];
  setTimelineEvents: Dispatch<SetStateAction<AppointmentTimelineEvent[]>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
  smsChecked: boolean;
  fastChecked: boolean;
  appointmentPatients: AppointmentPatientV2[];
  payments: Payment[];
  className?: string;
}

const DisplayableButtons = ({
  appointmentId,
  appointment,
  setAppointment,
  timelineEventsTags,
  setTimelineEvents,
  setLoading,
  setError,
  smsChecked,
  fastChecked,
  appointmentPatients,
  payments,
  className,
}: DisplayableButtonsProps): JSX.Element => {
  const [disabled, setDisabled] = useState<{ notify: boolean; visit: boolean }>({
    notify: false,
    visit: false,
  });

  const sendSMS = async (value: string): Promise<boolean> => {
    setDisabled((prev) => ({ ...prev, notify: true }));
    let isSuccessfulRequest = true;
    try {
      const params: { messageId: string; sms: "1" | "0"; variant?: "1" } = {
        messageId: value,
        sms: smsChecked ? "1" : "0",
      };
      if (fastChecked) {
        params.variant = "1";
      }
      const notifyReq = await api.request({
        method: "post",
        url: `dashboard/notify/${appointmentId}/sms/`,
        params: decamelizeKeys(params),
      });
      setTimelineEvents(notifyReq.data.timeline_events);
    } catch (err) {
      const error = err as AxiosError<{ err?: string }>;
      setError(error);
      Swal.fire({ title: "Error", icon: "error", text: error.response?.data?.["err"] });
      isSuccessfulRequest = false;
    }
    setDisabled((prev) => ({ ...prev, notify: false }));
    return isSuccessfulRequest;
  };

  const registerEvent = async (event: string) => {
    setLoading(true);
    const res = await appointmentAPI.registerEvent(appointmentId, event);
    if (res) {
      setAppointment(res);
      setTimelineEvents(res.timeline_events);
    }
    setLoading(false);
  };

  const finishAppointment = async (): Promise<boolean> => {
    setDisabled((prev) => ({ ...prev, visit: true }));
    let isSuccessfulRequest = true;
    try {
      const req = await appointmentAPI.patch(appointmentId, {
        status: "finished",
        finished: true,
      });
      setAppointment(req.data);
    } catch (err) {
      setError(err as AxiosError);
      isSuccessfulRequest = false;
    }
    setDisabled((prev) => ({ ...prev, visit: false }));
    return isSuccessfulRequest;
  };

  const onSetVisitedClick = async () => {
    if (payments?.some((payment) => payment.status === "Payment Pending" && payment.method !== "End of month")) {
      await Swal.fire({
        icon: "error",
        title: "Pagos pendientes",
        text: "Hay pagos pendientes en la cita, márcalos como pagados o elimínalos de la cita para poder marcarla como visitada",
        allowOutsideClick: false,
      });
      return;
    }
    let successfulRequest = true;
    if (appointmentPatients?.some((aptPatient) => aptPatient.patient.phone)) {
      successfulRequest = await sendSMS("visited");
    } else {
      successfulRequest = await finishAppointment();
    }
    if (successfulRequest) {
      await sendMedicalOrder({ asyncCall: true, isRegenerate: false });
    }
  };

  const sendMedicalOrder = async ({
    sendMail,
    asyncCall,
    current,
    isRegenerate,
  }: {
    sendMail?: boolean;
    asyncCall?: boolean;
    current?: number;
    isRegenerate: boolean;
  }) => {
    setLoading(true);
    try {
      const res = await medicalOrderService.createAndSend(appointmentId, isRegenerate, sendMail, asyncCall, current);
      return res;
    } catch (err) {
      setError(err as AxiosError);
    }
    setLoading(false);
  };

  const uploadToTotalpackDashboard = async (): Promise<void> => {
    setLoading(true);
    try {
      await appointmentService.sendToTotalpackDashboard(appointmentId);
      await Swal.fire({
        icon: "success",
        title: "Listo",
        text: "Para confirmar con totalpack dirigirse a la sección del dashboard de totalpack",
        allowOutsideClick: false,
      });
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Revisar que la cita tenga los servicios asignados a los pacientes",
        allowOutsideClick: false,
      });
    }
    setLoading(false);
    window.location.reload();
  };

  const removeFromTotalpackDashboard = async (): Promise<void> => {
    setLoading(true);
    try {
      await appointmentService.removeFromTotalpackDashboard(appointmentId);
      await Swal.fire({
        icon: "success",
        title: "Listo",
        text: "Cita eliminada del dashboard de totalpack",
        allowOutsideClick: false,
      });
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al eliminar la cita del dashboard de totalpack",
        allowOutsideClick: false,
      });
    }
    setLoading(false);
    window.location.reload();
  };

  const unvisitAppointment = async (): Promise<void> => {
    setDisabled(true);
    try {
      const response = await api.request({
        method: "post",
        url: `dashboard/appointments/${appointmentId}/unvisit_appointment/`,
      });
      setAppointment((prev) => {
        if (prev) {
          return {
            ...prev,
            status: response.data.data.status,
            finished: response.data.data.finished,
            timeline_events: response.data.data.timeline_events,
          };
        }
      });
      setTimelineEvents(response.data.data.timeline_events);
    } catch (err) {
      setError(err as AxiosError);
    }
    setDisabled(false);
  };

  const handleUnvisitConfirm = async () => {
    const check = await Swal.fire({
      title: "¿Estás seguro que quieres desmarcar el estado 'Visitado'?",
      icon: "warning",
      showCancelButton: true,
    });
    if (!check.isConfirmed) {
      return;
    }
    unvisitAppointment();
  };

  const sendResultsByWhatsapp = async () => {
    try {
      await notifyResultsReadyWhatsapp(appointmentId);
      Swal.fire({
        icon: "success",
        title: "Resultados enviados correctamente",
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: JSON.stringify(err.response?.data),
      });
    }
  };

  return (
    <div className={className}>
      <Button
        variant="outlined"
        color="primary"
        onClick={async () => sendSMS("contacted")}
        disabled={timelineEventsTags?.includes("contacted") || disabled["notify"] || disabled["visit"]}
        value="contacted"
      >
        Contactar
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={async () => sendSMS("on-the-way")}
        disabled={timelineEventsTags?.includes("on-the-way") || disabled["notify"] || disabled["visit"]}
        value="on-the-way"
      >
        En camino
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          registerEvent("arrived");
        }}
        disabled={timelineEventsTags?.includes("arrived") || disabled["notify"] || disabled["visit"]}
        value="arrived"
      >
        En domicilio
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          registerEvent("frustrated");
        }}
        disabled={timelineEventsTags?.includes("frustrated")}
      >
        Frustrado
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          registerEvent("needs-reschedule");
        }}
      >
        Necesita reagendar
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleUnvisitConfirm}
        disabled={!timelineEventsTags?.includes("visited") || disabled["visit"] || disabled["notify"]}
      >
        Desmarcar visitado
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={onSetVisitedClick}
        disabled={timelineEventsTags?.includes("visited") || disabled["notify"] || disabled["visit"]}
        value="visited"
      >
        Marcar visitado
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={async () => sendSMS("receipt-sent")}
        disabled={timelineEventsTags?.includes("receipt-sent") || disabled["notify"] || disabled["visit"]}
        value="receipt-sent"
      >
        Boleta enviada
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={async () => {
          Swal.fire({
            icon: "info",
            title: "generando OMs",
          });
          let statusBack = await sendMedicalOrder({ sendMail: false, asyncCall: true, isRegenerate: true, current: 0 });
          let current = statusBack?.data?.current || -1;
          let total = statusBack?.data?.total || 0;
          while (current < total) {
            Swal.fire({
              icon: "info",
              title: `Generando OMs, listas ${current} de ${total}`,
            });
            statusBack = await sendMedicalOrder({
              sendMail: false,
              asyncCall: true,
              isRegenerate: true,
              current: current,
            });
            current = statusBack?.data?.current ?? current + 1;
            total = statusBack?.data?.total ?? total;
          }
          Swal.fire({
            icon: "success",
            title: `generado OMs, listas ${current} de ${total}`,
          });
          setTimeout(() => {
            window.location.reload();
          }, 777);
        }}
        disabled={!timelineEventsTags?.includes("visited") || disabled["visit"] || disabled["notify"]}
      >
        Regenerar Orden Médica
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={async () => sendSMS("results-sent")}
        disabled={timelineEventsTags?.includes("results-sent") || disabled["notify"] || disabled["visit"]}
        value="results-sent"
      >
        Resultados disponibles
      </Button>
      {!appointment?.totalpack_request && (
        <Button
          variant="outlined"
          color="primary"
          onClick={uploadToTotalpackDashboard}
          disabled={disabled["notify"] || disabled["visit"]}
        >
          Enviar a dashboard totalpack
        </Button>
      )}
      {appointment?.totalpack_request && (
        <Button
          variant="outlined"
          color="primary"
          onClick={removeFromTotalpackDashboard}
          disabled={disabled["notify"] || disabled["visit"]}
        >
          Sacar de dashboard totalpack
        </Button>
      )}
      {appointment.is_operative && (
        <Button
          variant="outlined"
          color="primary"
          onClick={sendResultsByWhatsapp}
          disabled={disabled["notify"] || disabled["visit"]}
        >
          Enviar resultados por whatsapp
        </Button>
      )}
    </div>
  );
};

const Actionables = ({
  appointment,
  setAppointment,
  appointmentId,
  setError,
  setLoading,
  timelineEvents,
  setTimelineEvents,
  appointmentPatients,
  payments,
}: ActionablesProps) => {
  const [fastCheckbox, setFastCheckbox] = useState<boolean>(false);
  const [smsCheckbox, setSmsCheckbox] = useState<boolean>(true);
  const timelineEventsTags = useMemo(() => timelineEvents.map((event) => event.tag), [timelineEvents]);

  return (
    <Fragment>
      <div className="flex justify-between flex-row">
        <MaterialStepper
          stepsLabels={stepsLabels}
          completed={timelineSteps.map((step) => timelineEventsTags?.includes(step))}
        />
      </div>
      <DisplayableButtons
        appointmentId={appointmentId}
        appointment={appointment}
        setTimelineEvents={setTimelineEvents}
        timelineEventsTags={timelineEventsTags}
        setLoading={setLoading}
        setError={setError}
        smsChecked={smsCheckbox}
        fastChecked={fastCheckbox}
        setAppointment={setAppointment}
        appointmentPatients={appointmentPatients}
        payments={payments}
        className="flex justify-around mb-2 flex-wrap gap-y-2 items-center"
      />
    </Fragment>
  );
};

export default Actionables;
