import React, { useMemo } from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

import useAssistedSchedulingState from "~/stores/AssistedSchedulingStore";
import { CartItemObjectType } from "~/utils/interfaces/cartItem";
import { currencyFormat } from "~/utils/numbers";

type SearchServicesProps = {
  options: CartItemObjectType[];
  fonasa?: boolean;
  index: number;
  refetching?: boolean;
  query: string;
  showBasePrice: boolean;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  addService?: (index: number, newService: CartItemObjectType[]) => void;
  onlyPacks: boolean;
};

function ServicesSearch({
  options,
  fonasa,
  index,
  refetching,
  query,
  showBasePrice,
  setQuery,
  addService: addServiceProps,
  onlyPacks,
}: SearchServicesProps) {
  const addServiceHook = useAssistedSchedulingState((state) => state.addService);

  const addService = addServiceProps || addServiceHook;

  const handleChange = (_, newValue) => {
    addService(index, newValue);
    setQuery("");
  };

  const displayableServices = useMemo(
    () => (fonasa ? options?.filter((val: CartItemObjectType) => val.allowFonasaPrice) : options),
    [fonasa, options],
  );

  return (
    <Autocomplete
      disableClearable
      disableCloseOnSelect
      multiple
      options={displayableServices}
      getOptionLabel={(option: CartItemObjectType) => option.name}
      onChange={handleChange}
      inputValue={query}
      onInputChange={(_, newValue: string) => {
        setQuery(newValue);
      }}
      noOptionsText={refetching ? "Buscando..." : "No hay resultados :("}
      renderOption={(option: CartItemObjectType, { inputValue }) => {
        const matches = match(option.displayName || option.name, inputValue);
        const parts = parse(option.displayName || option.name, matches);
        const price = option.price({ fonasa: fonasa });
        const isAlteredPrice = (fonasa && option.allowFonasaPrice) || option.isPromotional;
        return (
          <div className="w-full flex justify-between">
            <div className="w-1/2 flex">
              {showBasePrice && isAlteredPrice && (
                <p className="line-through w-1/2 font-bold">{currencyFormat(option.basePrice)}</p>
              )}
              <p className="w-1/2 font-bold">{currencyFormat(price)}</p>
            </div>
            <div className="w-full">
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </div>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Busca y selecciona un ${onlyPacks ? "pack" : "servicio"}`}
          variant="outlined"
        />
      )}
    />
  );
}

export default ServicesSearch;
