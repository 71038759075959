import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { format, validate } from "rut.js";

import clsx from "clsx";
import appointmentService from "~/utils/api/v1/appointmentService";
import { Locale } from "~/utils/interfaces/Locale";
import { validateEmail } from "~/utils/regex";

export interface UploadDoctorInfoProps {
  appointmentPatient: string;
  active: boolean;
}

export interface DoctorData {
  name: string;
  document_number: string;
  email: string;
}

export interface FreezeInput {
  name: boolean;
  document_number: boolean;
  email: boolean;
}

export const UploadDoctorInfo = ({ appointmentPatient, active }: UploadDoctorInfoProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<string[]>([]);
  const [country, setCountry] = useState<Locale | undefined>(undefined);
  const [doctorData, setDoctorData] = useState<DoctorData>({ name: "", document_number: "", email: "" });
  const [freezeInput, setFreezeInput] = useState<FreezeInput>({ name: false, document_number: false, email: false });
  const [showUpload, setShowUpload] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    fetchData();
    setLoading(false);
  }, [appointmentPatient]);

  const fetchData = async () => {
    const doctor = await appointmentService.getDoctorData(appointmentPatient);

    if (doctor) {
      if (doctor.data.msg) {
        setDoctorData({ ...doctor.data.msg });
        // Lo siguiente genera un objeto que tiene como propiedad true
        // si la propiedad existe en el objeto doctorData
        setFreezeInput({
          ...freezeInput,
          ...Object.keys(doctor.data.msg).reduce(function (result, key) {
            result[key] = !!doctor.data.msg[key];
            return result;
          }, {}),
        });
      }
      setCountry(doctor.data.country);
      setShowUpload(doctor.data.needed_data);
    }
  };

  const validateFields = (): boolean => {
    const errors: string[] = [];
    if (!doctorData.document_number) {
      errors.push(`Debe ingresar ${country === "mx" ? "Cédula Profesional" : "RUT"}`);
    }
    if (country !== "mx") {
      if (!validate(doctorData.document_number!) && !!doctorData.document_number) {
        errors.push("Rut inválido");
      } else if (doctorData.document_number) {
        setDoctorData({
          ...doctorData,
          document_number: format(doctorData.document_number),
        });
      }
    }

    if (!validateEmail(doctorData.email) && !!doctorData.email) {
      errors.push("Mail inválido");
    }

    setError(errors);
    return !!errors.length;
  };

  const handleUploadData = async () => {
    setLoading(true);
    const valErrors = validateFields();

    if (valErrors) {
      setLoading(false);
      return;
    }

    appointmentService
      .uploadMedicalOrderDoctorData(appointmentPatient, {
        appointment_patient_id: appointmentPatient,
        name: doctorData.name,
        mail: doctorData.email,
        id: doctorData.document_number,
      })
      .then(() => {
        setOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        setError([`Ha habido un error: ${error.response.data.err}`]);
        setLoading(false);
      });
  };

  return (
    <div>
      {showUpload && (
        <h3
          className={clsx(
            "mb-1.5",
          !active && "text-[#D1CED1]",
          active && "mt-7.5",
        )}
      >
        Datos del doctor
        </h3>
      )}
      {showUpload && (
        <Button
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={() => setOpen(true)}
        >
          {loading ? "Cargando..." : "Subir Datos"}
        </Button>
      )}
      <Dialog open={open}>
        <DialogTitle>Datos del Doctor</DialogTitle>
        <DialogContent>
          Por favor ayúdanos a ingresando los datos del doctor que recetó la orden médica que estás subiendo.
        </DialogContent>
        <div className="flex flex-col px-20">
          <TextField
            label={country === "mx" ? "Cédula Profesional del Doctor" : "RUT del Doctor"}
            id="doctorId"
            disabled={loading || freezeInput.document_number}
            margin="dense"
            value={doctorData.document_number}
            onChange={(e) =>
              setDoctorData({
                ...doctorData,
                document_number: country === "mx" ? e.target.value : format(e.target.value),
              })
            }
            required={true}
          />
          <TextField
            label="Nombre del Doctor"
            id="doctorName"
            disabled={loading || freezeInput.name}
            margin="dense"
            value={doctorData.name}
            onChange={(e) =>
              setDoctorData({
                ...doctorData,
                name: e.target.value,
              })
            }
          />
          <TextField
            label="Correo del Doctor"
            id="name"
            disabled={loading || freezeInput.email}
            margin="dense"
            type="email"
            value={doctorData.email}
            onChange={(e) =>
              setDoctorData({
                ...doctorData,
                email: e.target.value,
              })
            }
          />
        </div>
        {loading && (
          <div className="flex flex-col items-center">
            <div className="p-12">
              <CircularProgress color="primary" />
            </div>
          </div>
        )}
        {!!error.length && !loading && (
          <div className="flex flex-col items-center">
            {error.map((err: string, index) => {
              return <DialogContent key={index}>{err}</DialogContent>;
            })}
          </div>
        )}
        <DialogActions>
          <Button
            disabled={loading}
            onClick={handleUploadData}
            color="primary"
          >
            Subir
          </Button>
          <Button
            disabled={loading}
            onClick={() => setOpen(false)}
            color="secondary"
          >
            Continuar sin subir datos
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
