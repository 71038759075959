import React, { useEffect, useMemo, useState } from "react";

import Swal from "sweetalert2";

import Button from "~/components/Buttons/Button";
import Modal from "~/components/Common/Modal";
import InputText from "~/components/inputs/InputText";
import { nursePaymentDetailDisplayName } from "~/components/nursePayments/constants";
import CrossIcon from "~/icons/CrossIcon";
import { editNursePayments } from "~/utils/api/v2/nursePayments";
import { NursePayment, NursePaymentDetail } from "~/utils/interfaces/nursePayments";

type CreateNursePaymentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  nursePayment?: NursePayment;
  refetchNursePayments: () => void;
};

function EditAppointmentNursePaymentModal({
  isOpen,
  onClose,
  nursePayment,
  refetchNursePayments,
}: CreateNursePaymentModalProps) {
  const [editedDetail, setEditedDetail] = useState<NursePaymentDetail>();
  const [loading, setLoading] = useState<boolean>(false);

  const hasBeenEdited = useMemo(
    () =>
      editedDetail &&
      Object.entries(editedDetail).some(([detail, amount]) => {
        return amount !== nursePayment?.detail[detail as keyof NursePaymentDetail];
      }),
    [editedDetail, nursePayment],
  );

  useEffect(() => {
    if (nursePayment && nursePayment.detail) {
      setEditedDetail(nursePayment.detail);
    }
  }, [nursePayment]);

  const onSubmit = async () => {
    if (!editedDetail || !hasBeenEdited || !nursePayment?.id) return;
    setLoading(true);
    const grossHtPayment = Object.entries(editedDetail)
      .filter(([key, _]) => key !== "total")
      .reduce((current, [_, amount]) => current + amount, 0);
    const detail = { ...editedDetail, total: grossHtPayment };

    const res = await editNursePayments(nursePayment?.id, { detail: detail, gross_ht_payment: grossHtPayment });
    if ("error" in res) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: res.error as string,
      });
      setLoading(false);
      return;
    }
    Swal.fire({
      title: "Pago editado",
      icon: "success",
      text: "El pago ha sido editado con éxito",
    });
    onClose();
    refetchNursePayments();
    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      header={
        <div>
          <div className="flex justify-end">
            <CrossIcon
              color="black"
              height={15}
              width={15}
              className="cursor-pointer"
              onClick={onClose}
            />
          </div>
          <p className="text-xl font-medium mb-0 py-3 border-b">Editar Pagos</p>
        </div>
      }
      content={
        <div className="flex flex-col overflow-y-scroll h-120">
          {editedDetail &&
            Object.entries(editedDetail).map(([detail, amount], index) => {
              if (["total", "override"].includes(detail)) return;
              return (
                <div key={index}>
                  <p className="text-md mb-0 mt-3 pb-1">
                    {nursePaymentDetailDisplayName[detail as keyof typeof nursePaymentDetailDisplayName]}
                  </p>
                  <InputText
                    id={detail}
                    name={detail}
                    value={String(amount)}
                    onChange={(e) => setEditedDetail({ ...editedDetail, [detail]: parseInt(e.target.value) || 0 })}
                  />
                </div>
              );
            })}
          <div className="flex flex-row my-8">
            <Button
              className="blue w-full mr-6"
              text="Guardar Pago"
              disabled={!hasBeenEdited}
              isLoading={loading}
              onClick={onSubmit}
            />
            <Button
              className="white w-full"
              text="Salir sin guardar"
              onClick={onClose}
            />
          </div>
        </div>
      }
      hideFooter
    />
  );
}

export default EditAppointmentNursePaymentModal;
