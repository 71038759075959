import React from "react";

import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";

export default function MaterialStepper({ stepsLabels, completed }: { stepsLabels: Array<string>; completed: Array }) {
  return (
    <div className="w-full">
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={-1}
      >
        {stepsLabels.map((label, index) => {
          return (
            <Step key={label}>
              <StepButton completed={completed[index]}>{label}</StepButton>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
